import type { Timestamp } from './types'

type Change = {
  type: string
  title: string
  description: string
}

type Version = {
  number: string
  timestamp: Timestamp
  changes: Array<Change>
}

type TypedChange = (title: string, description: string) => Change

const version = (number: string, timestamp: string, changes: Array<Change>): Version => ({
  number,
  timestamp,
  changes,
})

const changeType =
  (type: string) =>
  (title: string, description: string): Change => ({
    type,
    title,
    description,
  })

const newFeature: TypedChange = changeType('new-feature')
const featureChange: TypedChange = changeType('change')
const bugfix: TypedChange = changeType('bugfix')

const changelog_prod: Array<Version> = [
  version('5.12.1', '2025-01-28T09:30:00', [
    bugfix('Jarrupainot', 'P-jarrulajin tavarajunaveturien jarrupainot näkyvät nyt oikein'),
  ]),
  version('5.12.0', '2024-12-11T13:00:00', [
    bugfix(
      'Energiatehokkuudet',
      'Parannettu energiatehokkuuksien kohdentamista taustajärjestelmissä'
    ),
    newFeature(
      'Edellisten häiriötilanteiden palautelinkit',
      'Vuorossa näkyy palautelinkit myös lähiaikoina tapahtuneisiin edellisiin vakaviin tai keskivakaviin häiriötilanteihin'
    ),
    bugfix(
      'Liito-viestien taustapäivitys',
      'Liito-viestien taustapäivitystä muutettu niin, ettei se aiheuta turhia ilmoituksia'
    ),
    featureChange(
      'Työvuorohaku',
      'Työvuoroja voi nyt hakea junanumerolla ja päivämäärällä, ja työvuorohaku näkyy nyt myös lähiliikenteen kuljettajien ohjaajille'
    ),
  ]),
  version('5.11.1', '2024-11-26T12:00:00', [
    bugfix(
      'SM-liikenteen merkittävä häiriötilanne',
      'Korjattu taustajärjestelmän toimintaa viestien SM-liikenteen merkittävän häiriötilanteen viestien tallennuksessa.'
    ),
    bugfix(
      'Liito-häiriöviestit',
      'Korjattu edustasovelluksen toimintaa Liito-häiriöviestien häiriötason käsittelyssä.'
    ),
    bugfix(
      'Kokoonpanotiedot junatarjoilijoille',
      'Lisätty junatarjoilijoille luvitus käsittelyaseman hakemiseen, jolloin kokoonpanojen haku toimii oikein.'
    ),
  ]),
  version('5.11.0', '2024-11-19T11:30:00', [
    newFeature(
      'Liito-häiriöviestit',
      'Lisätty junatarjoilijat, sekä lähiliikenteen että logistiikan kuljettajat vastaanottajiksi.'
    ),
    featureChange(
      'Liito-häiriöviestit',
      'Palautelomakkeen linkki näytetään myös keskitason häiriöiviesteille.'
    ),
    featureChange(
      'Junatarjoilijoille laajennus työvuoroihin',
      'Junatarjoilijoille näytetään toteutuneet myyntivaunut ja kokoonpanotiedot.'
    ),
    bugfix(
      'SM-liikenteen merkittävä häiriötilanne',
      'Korjattu taustajärjestelmän toimintaa viestien lähetyksessä.'
    ),
  ]),
  version('5.10.0', '2024-11-15T12:00:00', [
    newFeature(
      'SM-liikenteen merkittävä häiriötilanne',
      'Kytketty päälle SM-liikenteen merkittävien häiriötilanteiden käsittely. Pilotointi 6.11.'
    ),
    newFeature(
      'Työvuoro-ohje',
      'Lisätty tehtävän kalustolle täsmällisyystieto, kun edeltävä juna tiedossa ja kulkee tästä hetkestä tunnin sisään.'
    ),
    featureChange('Haastot', 'Taustajärjestelmän muutos haastosähköpostien kohdistamisessa.'),
    featureChange(
      'Työvuoroluettelo',
      'Tyhjät päivät vuorojen välillä näkyvät työvuoroluettelossa.'
    ),
  ]),
  version('5.9.3', '2024-10-22T15:30:00', [
    bugfix('Roolitus', 'Tuki junatarjoilijoiden roolille Kentässä.'),
  ]),
  version('5.9.0', '2024-10-21T10:00:00', [
    newFeature(
      'Junatarjoilijoiden työvuoro',
      'Tuki junatarjoilijoiden työvuorojen esittämiselle Kentässä.'
    ),
    featureChange(
      'Yhteystiedot työvuorolla ja henkilökuntahaku',
      'Tuki junatarjoilijoiden esittämiseen työvuoron yhteystiedoissa ja henkilökuntahaussa.'
    ),
  ]),
  version('5.8.0', '2024-10-16T14:00:00', [
    featureChange(
      'Parannuksia ja korjauksia vikojen hakuun ja ilmoittamiseen',
      'Kriittisyystiedon valintaa ei näytetä, luodut viat saavat oletuskriittisyyden. Käyttöä rajoittavista vioista soitettava OPK:lle. Viat järjestetään kriittisyyden ja rajoitteiden perusteella. Rajoitetiedot on nostettu paremmin näkyviin. Sijainti kalustossa tieto välitetään oikein taustajärjestelmiin.'
    ),
    featureChange('Ajotietotuloste', 'Ajotietotuloste ei voi koskaan sisältää matkustuksen junia.'),
    featureChange(
      'Huoltotyöt',
      'Korjattu virheilmoitusta, joka näytetään kun huoltotyötä yritetään aloittaa suunnitellun ajan jälkeen.'
    ),
    bugfix(
      'Toteutuneet myyntivaunut',
      'Korjattu bugi, jonka johdosta toteutuneita myyntivaunuja ei olla onnistuttu hakemaan edellisen päivityksen jälkeen'
    ),
    bugfix('Energiatiedot', 'Vaihtotyöt, ja muut ei-ajotehtävät, on poistettu energiatiedoista'),
  ]),
  version('5.7.0', '2024-10-08T12:00:00', [
    newFeature(
      'Kaukoliikenteen työvuorot',
      'Työvuorot näyttävät vahvat vapaat ja heittopäivät. Myös ICS-kalenterissa'
    ),
    newFeature(
      'Push-viestit',
      'Käyttäjille joiden kuuluisi saada push-ilmoituksia, näytetään virheilmoitus mikäli heidän laitteellaan ei ole tukea push-ilmoitusten vastaanottamiseen'
    ),
  ]),
  version('5.6.13', '2024-09-27T13:00:00', [
    bugfix('Työvuoro-ohje', 'Korjattu infotekstin rivitys pitkille sanoille'),
  ]),
  version('5.6.12', '2024-09-11T11:00:00', [
    bugfix(
      'Lähiliikenteen työvuorot',
      'Lähiliikenteen työvuoroilla ollut puuttuvia lähtöraiteita, korjattua taustajärjestelmän käsittelyä.'
    ),
    bugfix(
      'Ajotietotuloste',
      'Ajotietotulosteen latauksessa näkynyt virheellinen varoitusteksti Kuplan käytöstä. Teksti poistettu.'
    ),
    newFeature(
      'Yleinen',
      'Tekninen vienti, jolla ei ole vaikutusta tuotantoympäristössä. SM-liikenteen merkittävä häiriötilanne ja lähiliikenteen työvuorojen pseudonymisointi.'
    ),
  ]),
  version('5.6.11', '2024-09-04T10:00:00', [
    featureChange(
      'Liito-häiriöviestit',
      'Liito-häiriöviestit sallittu kaikille kaukoliikenteen konduktööreille.'
    ),
  ]),
  version('5.6.10', '2024-08-22T11:00:00', [
    bugfix('Lisää sivu', 'Piilotettu ei-admin -käyttäjiltä linkki kehittäjien työkalusivuun.'),
  ]),
  version('5.6.9', '2024-08-20T15:15:00', [
    bugfix(
      'Push-viestien tilauksen päivittäminen',
      'Push-viestien tilauksen pystyy nyt uusimaan Lisää-sivun kautta napin painalluksella.'
    ),
  ]),
  version('5.6.8', '2024-08-13T10:30:00', [
    bugfix('Push-notifikaatiot', 'Korjaus uusien push-tilausten muodostamiseen.'),
  ]),
  version('5.6.4', '2024-06-20T10:00:00', [
    bugfix('Push-notifikaatiot', 'Korjaus Push-notifikaatioiden virhekäsittelyyn.'),
  ]),
  version('5.6.3', '2024-06-19T10:00:00', [
    bugfix(
      'Liito-häiriöviestit',
      'Korjaus Liito-häiriöviestien lataamiseen; viestejä ladataan vasta kun käyttäjän henkilöryhmä on tiedossa.'
    ),
  ]),
  version('5.6.0', '2024-06-05T12:00:00', [
    newFeature(
      'Liito-häiriöviestit',
      'Liito-häiriöviestien näkyminen konduktöörien-pilottiryhmälle.'
    ),
  ]),
  version('5.5.6', '2024-04-22T11:30:00', [
    featureChange(
      'eSälli-kirjautuminen',
      'Muutettu datan käsittelyn logiikkaa, siten että tunnistettu eSällin käyttäjä pystyy hakemaan työvuoroja silloinkin kun omassa listassa ei ole työvuoroja.'
    ),
    bugfix('Energiatiedot', 'Korjaus työvuorojen tallennukseen.'),
  ]),
  version('5.5.5', '2024-03-25T09:00:00', [
    featureChange('Taustajärjestelmä', 'Optimointeja taustajärjeselmän tekemiin kyselyihin'),
    bugfix('Vikailmoittaminen', 'Korjaus tiedostojen lataamiseen'),
    bugfix('Veksi / Lähiveksi -ilmoitukset', 'Korjaus ilmoitusten näkymiseen'),
  ]),
  version('5.5.4', '2024-03-12T11:30:00', [
    bugfix(
      'Haastot',
      'Haastojen lähetys epäonnistui kun syykoodi oli kohdistettu asemalta lähtöön.'
    ),
  ]),
  version('5.5.3', '2024-03-07T12:30:00', [
    featureChange(
      'Käyttörajoitehaku',
      'Käyttörajoiteet haetaan työvuoronäkymälle 1 min sijasta 5 min välein. Käyttörajoitteet saa haettua myös manuaalisesti päivittämällä selainsivun.'
    ),
  ]),
  version('5.5.2', '2024-03-06T11:00:00', [
    bugfix('Huoltotyöt', 'Korjaus huoltokuljettajien roolin tunnistukseen'),
  ]),
  version('5.5.1', '2024-03-06T11:00:00', [
    bugfix('Henkilökuntahaku', 'Korjaus tietojen näyttöön'),
  ]),
  version('5.5.0', '2024-03-05T14:00:00', [
    newFeature(
      'Huoltotyöt',
      'Lisätty huoltokuljettajille mahdollisuus suorittaa pikkuhuoltoja Kentässä'
    ),
  ]),
  version('5.3.1', '2024-03-05T09:00:00', [
    featureChange(
      'Vikailmoittaminen',
      'Yksinkertaistettu vikailmoittamisen lomaketta, lisätty mahdollisuus ladata myös suljettuja vikoja'
    ),
    bugfix('Vikailmoittaminen', 'Korjaus vikojen statuksen käsittelyyn'),
    bugfix('Vikatiedot', 'Kriittisyysluokan 3 näkyminen oikein jos vialla on käyttörajoite'),
  ]),
  version('5.2.0', '2024-02-06T09:30:00', [
    bugfix('Yleinen', 'Korjaus versiopäivityksen aiheuttamiin ongelmiin'),
  ]),
  version('5.1.2', '2023-12-11T11:00:00', [
    featureChange('Ajotietotuloste', 'Ajotietotuloste haetaan Myllyn kautta'),
    newFeature('Yleinen', 'Uusi AD-ryhmä lähiliikenteen komennuksia tekeville'),
    featureChange(
      'Haastot',
      'Haastoviestien vastaanottajat siirretty ylläpidettäväksi Contentfuliin'
    ),
  ]),
  version('5.1.1', '2023-11-15T12:00:00', [
    featureChange('Yleinen', 'Korjaus versiopäivityksen aiheuttamiin ongelmiin'),
    featureChange('Yleinen', 'Korjaus sovelluksen sisäisiin sivunavigaatioihin'),
  ]),
  version('5.1.0', '2023-11-06T15:00:00', [
    newFeature(
      'Työvuorohaku',
      'Lisätty mahdollisuus lähiliikenteen kuljettajien hakea työvuoroa työvuorotunnuksen perusteella'
    ),
  ]),
  version('5.0.2', '2023-10-11T17:30:00', [
    bugfix('Kokoonpanohaku', 'Korjaus kokoonpanotietojen hakemiseen.'),
  ]),
  version('5.0.1', '2023-10-11T12:00:00', [
    bugfix('Työvuoronäkymä', 'Korjaus junahenkilökunnan näyttämisessä työvuorolla'),
    bugfix('Yleinen', 'Korjauksia yöteeman väreihin'),
  ]),
  version('5.0.0', '2023-10-09T13:00:00', [
    newFeature(
      'Vikailmoittaminen',
      'Lisätty uusi toiminnallisuus vikojen selaamiseen ja ilmoittamiseen DM12 kalustoille.'
    ),
    featureChange('Yleinen', 'Uudistettu paikoitellen sovelluksen käyttöliittymää, mm. hakusivu.'),
  ]),
  version('4.17.0', '2023-09-19T12:00:00', [
    featureChange('Työvuoronäkymä', 'Vanhojen lähiliikenteen työvuorojen poistaminen'),
    bugfix('Täsmällisyystiedot', 'Korjaus kehäradan junien täsmällisyystietoihin'),
    featureChange(
      'Kokoonpanotiedot',
      'Pendolinojen kokoonpanotietojen käsittelyä parannettu, jotta ne eivät näy konduktööreille aina muuttuneella kokoonpanolla'
    ),
    featureChange('Työvuorohaku', 'Muutettu työvuorohaussa näkymään työvuoron alku- ja loppuajat'),
    newFeature('Käyttöoikeudet', 'Lähiliikenteen kuljettajien ohjaajille uusi rooli'),
    featureChange(
      'Työvuoronäkymä',
      'Lähiliikenteen työvuorojen päivittyminen näkymään työvuoron poiston yhteydessä'
    ),
    featureChange(
      'Työvuoropalaute',
      'Työvuoropalaute vapaatekstikentän pituusrajoituksen huomiointi'
    ),
    newFeature('Kokoonpanotiedot', 'Kokoonpanotietojen tarkastelu puhelimen ollessa vaakasuuntaan'),
  ]),
  version('4.16.0', '2023-08-24T15:00:00', [
    featureChange('Yleinen', 'Päivitetty lisää-sivun PowerBI-linkki'),
  ]),
  version('4.15.0', '2023-08-07T12:30:00', [
    featureChange('Työvuoronäkymä', 'Päivitetty palkattoman ruokatauon mäppäystä'),
  ]),
  version('4.14.0', '2023-06-26T12:00:00', [
    featureChange('Työvuoropalaute', 'Palaute työvuorosta poistettu lähiksen kuljettajilta'),
    bugfix('Työvuoropalaute', 'eSälli-palautteen latausindikaattorin UI-korjaus'),
    bugfix(
      'Käyttörajoitteet',
      'Käyttörajoitehaku ei toimi oikein jos kalustolla yli 30 rajoitetta'
    ),
    bugfix('Kalenteri', 'Google kalenteriviennin korjaukset'),
    featureChange('Yleinen', 'Google Analytics poistettu käytöstä'),
    newFeature('Veksi / LähiVeksi ilmoitukset', 'Poistettujen ilmoitusten poistaminen Kentästä'),
  ]),
  version('4.13.0', '2023-05-09T12:30:00', [
    newFeature(
      'Käyttöoikeudet',
      'Logistiikan RO-kuljettajien ja vaihtotyönjohtajien eriyttäminen omalle roolilleen'
    ),
    bugfix('Käyttöoikeudet', 'Kentän sessiovanhenemisen parantaminen'),
    bugfix('Energiatehokkuus', 'Energiatehokkuuden korjaukset'),
  ]),
  version('4.12.0', '2023-04-26T13:00:00', [
    newFeature('Lähiliikenteen työvuorot', 'Lisätty palkaton ruokatauko riveille kellonajat'),
    bugfix('Lähiliikenteen työvuorot', 'Osavälin kulkevat konduktöörit näkyivät koko matkan'),
  ]),
  version('4.11.0', '2023-04-04T12:00:00', [
    newFeature(
      'Energiatehokkuus',
      'Energiatehokkuustieto näytetään junaliikennöinnin kuljettajille'
    ),
    newFeature('Veksi/LähiVeksi', 'Veksi ja Lähiveksi uutiset näytetään kuljettajille'),
  ]),
  version('4.10.6', '2023-01-31T13:00:00', [
    bugfix('Hinauksenhallinta', 'Uusien kalustojen luotettavuusparannukset'),
  ]),
  version('4.10.5', '2023-01-10T13:00:00', [
    bugfix(
      'Hinauksenhallinta',
      'Korjaus tilanteeseen missä hinauksenasetuslomake on jäänyt jumiin'
    ),
  ]),
  version('4.10.4', '2023-01-03T13:00:00', [
    bugfix('Elpymisprosentit', 'Korjaus lähiliikenteen työvuorojen elpymisprosenttien näkyvyyteen'),
  ]),
  version('4.10.3', '2022-12-21T13:00:00', [
    featureChange('Hinauksenhallinta', 'Hinauksen yleisohjeen linkin päivittäminen'),
  ]),
  version('4.10.0', '2022-11-15T12:00:00', [
    featureChange(
      'Elpymisprosentit',
      'Näytetään lähiliikenteen kuljettajille työvuoron elpymisprosentit, mikäli tiedossa'
    ),
  ]),
  version('4.9.0', '2022-10-17T11:00:00', [
    featureChange(
      'Hinauksenhallinta',
      'Hinauksenhallinnan tehtyjen tehtävien näyttäminen paikallapysymisen varmistamisen lomakkeella'
    ),
  ]),
  version('4.8.0', '2022-10-04T14:00:00', [
    featureChange('Kokoonpanomuutokset', 'Parannuksia kokoonpanohakujen suorituskykyyn'),
  ]),
  version('4.7.3', '2022-09-22T11:00:00', [
    bugfix('Työvuoronäkymä', 'Korjaus vikatietojen hakuun'),
  ]),
  version('4.7.2', '2022-09-21T13:00', [
    newFeature('MoTo-toiminnallisuudet', 'Vikatietojen haun tukeminen MoTosta'),
    newFeature(
      'Kaukoliikenteen kokoonpanot',
      'Muuttuneiden kokoonpanojen näyttäminen konduktööreille'
    ),
    featureChange(
      'Hinauksenhalinta',
      'Lisäty kehotus tarkistaa ajossa olevan hinauskuntoisen veturin tila'
    ),
    featureChange('Hinauksenhallinnan lomakkeet', 'Parannuksia muutostilanteisiin'),
    featureChange('Lähiliikenteen työvuorot', 'Parannuksia työvuoronäkymään'),
    featureChange(
      'Kaukoliikenteen työvuorot',
      'Näytetään myös alle 1h ja yli 9h levot työvuoroilla'
    ),
    bugfix('Haku', 'Kalenteri esitetään suomalaisessa formaatissa'),
    bugfix('Vikatiedot', 'Korjattu vikatietonäkymän kaatuminen, kun kalustolla ei ole vikatietoja'),
  ]),
  version('4.6.9', '2022-09-14T12:00', [
    bugfix('Yleinen', 'Parannettu taustajärjestelmän luotettavuutta'),
  ]),
  version('4.6.8', '2022-07-18T21:40:00+03:00', [
    bugfix('Yleinen', 'Parannettu virheenkäsittelyä taustajärjestelmässä'),
  ]),
  version('4.6.7', '2022-07-18T21:40:00+03:00', [
    featureChange('Vikatiedot', 'Parannettu vikatietojen päivitystä'),
  ]),
  version('4.6.6', '2022-06-21T14:00:00+03:00', [
    bugfix('Kalenterilinkki', 'Korjattu vika kalenterilinkin toiminnassa'),
  ]),
  version('4.6.5', '2022-06-20T14:00:00+03:00', [
    bugfix(
      'Käyttäjäryhmät',
      'Korjattu vika, jossa kunnossapito ei pystynyt käyttämään hinauksenhallintaa'
    ),
  ]),
  version('4.6.4', '2022-06-20T12:00:00+03:00', [
    featureChange('Yleistä', 'Parannettu uloskirjautumista'),
    bugfix(
      'Lähiliikenteen työvuorot',
      'Parannettu käyttöliittymää tilanteissa, jossa työvuoroja ei löydy.'
    ),
  ]),
  version('4.6.3', '2022-06-15T12:00:00+03:00', [
    bugfix('Haastot', 'Korjattu vika haastojen ja tarkennusten näkymässä'),
    bugfix(
      'Työvuoronäkymä',
      'Parannettu vuoron aloitus- ja lopetusaikojen luettavutta yömoodissa. Yleisiä parannuksia.'
    ),
  ]),
  version('4.6.2', '2022-06-15T12:00:00+03:00', [
    bugfix('Hinauksenhallinta', 'Parannettu taustajärjestelmän julkaisuprosessia'),
  ]),
  version('4.6.1', '2022-06-15T12:00:00+03:00', [bugfix('Yleinen', 'Versiohistorian korjaus')]),
  version('4.6.0', '2022-06-15T12:00:00+03:00', [
    newFeature(
      'Hinauksenhallinta',
      'Lisätty Kenttään toiminnallisuus hinausten hallintaan ja tarkasteluun.'
    ),
    bugfix('Yleinen', 'Parannettu uloskirjautumisen toimintaa'),
    bugfix('Yleinen', 'Yleisiä käytettävyysparannuksia'),
  ]),
  version('4.5.0', '2022-05-17T13:00:00+03:00', [
    featureChange(
      'Lähiliikenteen työvuorot',
      'Lähiliikenteen työvuorot julkaistaan nyt kolmen viikon syklillä.'
    ),
    featureChange(
      'Lähiliikenteen työvuorot',
      'Parannus työvuorojen muutosten ja perumisten käsittelyyn'
    ),
    featureChange('Yleinen', 'Yleisiä parannuksia Kentän ja taustajärjestelmän toimintaan'),
  ]),
  version('4.4.2', '2022-04-12T12:00:00+03:00', [
    featureChange(
      'Lähiliikenteen työvuorot',
      'Lähiliikenteen työvuorot näytetään nyt pitemmälle tulevaisuuteen. Menneistä vuoroista näytetään enää vain ne, joiden alkamisesta on alle vuorokausi.'
    ),
  ]),
  version('4.4.1', '2022-02-14T14:00:00+02:00', [
    bugfix('Työvuoronäkymä', 'Korjattu vika, joka tietyissä tilanteissa kaataa käyttöliittymän'),
  ]),
  version('4.4.0', '2022-02-08T10:00:00+02:00', [
    featureChange('Kokoonpanohaku', 'Uudistettu kokoonpanohaku'),
    bugfix('Ajotietotuloste', 'Pieniä korjauksia ajotietotulosteen tiedostonimeen'),
    bugfix('Työvuoronäkymä', 'Pieniä ulkoasuparannuksia ja -korjauksia'),
    bugfix('Työvuoroluettelo', 'Pieniä esitys- ja ulkoasuparannuksia'),
  ]),
  version('4.3.0', '2021-12-14T14:30:00+02:00', [
    bugfix('Yleinen', 'Parannettu kentän vakautta'),
    bugfix('Yleinen', 'Pieniä ulkoasuparannuksia ja -korjauksia'),
    featureChange('Ajotietotuloste', 'Ajotietotulosteen tiedostonimi on nyt yksilöivä ja luettava'),
    featureChange(
      'Ratatiedot',
      'Tehtävän lähtö- ja tuloraiteet pystytään nyt näyttämään useammassa tilanteessa'
    ),
    bugfix('Taustapalvelut', 'Yleisiä parannuksia taustajärjestelmien kanssa kommunikointiin'),
  ]),
  version('4.2.3', '2021-12-01T17:00:00+02:00', [
    featureChange('Käyttäjäryhmä', 'Lisätty uusi käyttäjäryhmä'),
  ]),
  version('4.2.2', '2021-10-15T11:30:00+03:00', [
    featureChange('Käyttäjäryhmät', 'Käyttäjäryhmissä tehty pieniä muutoksia'),
  ]),
  version('4.2.1', '2021-09-14T11:30:00+03:00', [
    bugfix('Taustapalvelut', 'Korjattu vika sähköpostilähetyksessä'),
  ]),
  version('4.2.0', '2021-09-29T11:30:00+03:00', [
    featureChange('Taustapalvelut', 'Yleisiä parannuksia taustajärjestelmän toimintaan'),
    bugfix(
      'Lähiliikenteen yhteystiedot',
      'Korjattu vika, jossa lähiliikenteen työtehtävien yhteystiedoilla näkyi vääriä asemavälejä'
    ),
  ]),
  version('4.1.1', '2021-07-15T17:00:00+03:00', [
    featureChange('Taustapalvelut', 'Tehty parannuksia taustajärjestelmän lokitukseen'),
  ]),
  version('4.1.0', '2021-06-21T11:00:00+03:00', [
    newFeature(
      'Lähiliikenteen työvuorot',
      'Työvuorojen lisääminen kalenteriin toimii nyt myös lähiliikenteen työvuoroilla'
    ),
    featureChange('Taustapalvelut', 'Tehty parannuksia taustajärjestelmän lokitukseen'),
  ]),
  version('4.0.3', '2021-06-21T11:00:00+03:00', [
    bugfix(
      'Ajotietotuloste',
      'Korjattu vika, jossa lähiliikenteen kuljettaja ei pystynyt hakemaan ajotietotulostetta ajamiseen'
    ),
  ]),
  version('4.0.2', '2021-06-21T11:00:00+03:00', [
    bugfix('Kalenteri', 'Korjattu väärät kellonajat omaan kalenteriin lisättävissä työvuoroissa'),
  ]),
  version('4.0.1', '2021-06-21T11:00:00+03:00', [
    newFeature(
      'Lähiliikenteen poikkeusraportointi',
      'Lähiliikenteelle lisätty mahdollisuus lähettää poikkeusraportteja'
    ),
    newFeature(
      'Lähiliikenteen asiakaspalaute',
      'Lähiliikenteelle lisätty mahdolisuus lähettää asiakkailta kerättyä palautetta'
    ),
    newFeature(
      'Lähiliikenteen yhteystiedot',
      'Lähiliikenteen työtehtäviin lisätty konduktöörien yhteystiedot, tieto siitä onko konduktööri junassa ja mahdollisuus soittaa konduktöörille'
    ),
    newFeature(
      'Lähiliikenteen täsmällisyystiedot',
      'Lähiliikenteelle lisätty lisää-sivulle linkki, josta pääsee tarkastelemaan omia täsmällisyystietojaan'
    ),
    bugfix('Lähiliikenteen työvuorot', 'Parannettu lähiliikenteen työvuoronäkymää'),
    bugfix('Lähiliikenteen ohjauksen viestit', 'Parannettu ohjauksen viestien luotettavuutta'),
    featureChange('Yleinen', 'Parannuksia kentän ulkoasuun'),
  ]),
  version('3.2.1', '2021-05-28T10:00:00+03:00', [
    bugfix(
      'Työvuoro-ohje',
      'Korjattu virhetilanne, jossa tehtävän henkilöstö näkyi virheellisesti.'
    ),
  ]),
  version('3.2.0', '2021-05-25T11:00:00', [
    featureChange('Yleistä', 'Parannettu sovelluksen nopeutta'),
    newFeature(
      'Lähiliikenteen ohjauksen viestit',
      'Lisää -sivulle on nyt lisätty työkalu ilmoitusten testaamiseen'
    ),
    newFeature('Kokoonpanotiedot', 'Lisätty tieto tyhjistä konteista ja kuormakonteista'),
    featureChange(
      'Lähiliikenteen ohjauksen viestit',
      'Ilmoituksen avaaminen vie nyt suoraan työvuoronäkymään'
    ),
    bugfix('Lähiliikenteen työvuorot', 'Pieniä parannuksia'),
    bugfix(
      'Lähiliikenteen ohjauksen viestit',
      'Paranettu viestien luotettavuutta ja korjattu löytyneitä vikoja'
    ),
  ]),
  version('3.1.0', '2021-04-22T12:00:00+0300', [
    bugfix(
      'Yleinen',
      'Yleisiä parannuksia sovelluksen toimintaan ja push-ilmoitusten käsittelyyn.'
    ),
  ]),
  version('3.0.0', '2021-04-07T13:50:33+0300', [
    newFeature('Lähiliikenteen työvuorot', 'Aloitetaan työvuorojen pilotointi.'),
  ]),
  version('2.3.4', '2021-04-01T08:46:02+03:00', [
    bugfix(
      'Jarrupainotietojen haku',
      'Kokoonpanohauista ja muista toiminnallisuuksista automaattiset uudelleenyritykset poistettu.'
    ),
  ]),
  version('2.3.3', '2021-01-20T13:40:00+03:00', [
    bugfix(
      'Työvuoro-ohje',
      'Konduktöörien puuttuneet vaihtotyötunnukset lisätty tehtävän nimeen tai tehtävän tietoihin'
    ),
  ]),
  version('2.3.2', '2020-10-22T15:00:00+03:00', [
    bugfix('Työvuoro-ohje', 'Korjattu vara-ajotietotulosteen infoteksti'),
    bugfix('Tänään-näkymä', 'Korjattu ilmoittautumisen aikaraja'),
  ]),
  version('2.3.1', '2020-10-22T13:00:00+03:00', [
    bugfix('Työvuoroluettelo', 'Korjattu työvuorojen kellonajat talviaikaan siirtymisen aikana'),
    bugfix('Työvuoroluettelo', 'Korjattu ajotietotulosteen lataus työvuoronäkymästä'),
    bugfix('Tänään-näkymä', 'Korjattu työvuoroon ilmoittautumisen painike'),
  ]),
  version('2.3.0', '2020-10-07T13:00:00+03:00', [
    newFeature('Työvuoroluettelo', 'Lisätty lepotietoon mahdollisuus näyttää lisätietoja'),
    bugfix(
      'Työvuoroluettelo',
      'Kaksiosaisen työvuoron toiseen osaan ei voi enää ilmoittautua liian aikaisin'
    ),
    bugfix(
      'Työvuoroluettelo',
      'Ilmoittaudu-painike näkyy samalla tavalla mobiililaittella ja tietokoneella'
    ),
    featureChange('Työvuoro-ohje', 'Työvuoron lisätiedon näkyvyyttä parannettu'),
    bugfix(
      'Työvuoro-ohje',
      'Korjattu tilanne, jossa aseman lyhenteen viimeinen kirjain leikkautui'
    ),
    newFeature('Tänään-näkymä', 'Seuraavan työvuoron päivämäärän lisätty viikonpäivä'),
    bugfix(
      'Hakunäkymä',
      'Näppäimistö ei enää nouse esiin mobiililaittella päivämäärää valittaessa '
    ),
    newFeature('Kokoonpanotiedot', 'Kenttä tallentaa vaununumeron esitystavan valinnan'),
    bugfix(
      'Yleistä',
      'Korjattu vika, jossa Kenttä-sovellusta ei voinut avata verstaan sovellussivuston linkistä'
    ),
    bugfix('Yleistä', 'Parannettu työvuoron parsinnan luotettavuutta'),
    bugfix('Virhenäkymä', 'Parannettu virhenäkymästä palaamisen luotettavuutta'),
    featureChange('Taustapalvelut', 'Tehty parannuksia taustajärjestelmän lokitukseen'),
    newFeature('Ajotietotuloste', 'Lisätty linkki ajotietotulosteen varajärjestelmään'),
  ]),
  version('2.2.4', '2020-08-19T12:00:00+03:00', [
    newFeature(
      'Kalenteri',
      'Työvuorot voi nyt lisätä omaan kalenteriin, tarkemmat ohjeet löytyvät Lisää-välilehdeltä'
    ),
    bugfix('Yleistä', 'Virhetilanteissa näytetään nyt useammin virheilmoitus tyhjän ruudun sijaan'),
    bugfix('Hakunäkymä', 'Onnistuneen haun jälkeen paluunappi oli näkymätön yömoodissa'),
    bugfix(
      'Käyttörajoitetiedot',
      'Yksittäisen kaluston käyttörajoitteita avattaessa siirtyy näkymä automaattisesti oikean kaluston kohdalle'
    ),
    bugfix(
      'Työvuoronäkymä',
      'Täsmällisyystietoja ei pysty enää hakemaan työvuoroille, joilla näitä tietoja ei ole'
    ),
  ]),
  version('2.2.3', '2020-08-05T12:00:00+03:00', [
    bugfix('Työvuoro-ohje', 'Korjattu konduktöörien tehtävätunnusten näyttö'),
  ]),
  version('2.2.2', '2020-07-22T13:00:00+03:00', [
    newFeature(
      'Työvuoro-ohje',
      'Sm3 ja Sm6 -kalustojen käyttörajoitteet näytetään yksikön kaikille vaunuille'
    ),
    bugfix('Täsmällisyystiedot', 'Tehdyt haastot näkyvät täsmällisyysnäkymässä'),
    bugfix('Työvuoro-ohje', 'Parannettu työvuoro-ohjeen päivityspainikkeen luotettavuutta'),
    bugfix(
      'Kaksiosaiset työvuorot',
      'Muutettu sovelluksen logiikkaa, jonka vuoksi kaksiosaisen työvuoron jälkimmäinen osa ei aina näkynyt käyttäjälle.'
    ),
  ]),
  version('2.2.0', '2020-06-25T13:00:00+03:00', [
    newFeature(
      'Täsmällisyystiedot',
      'Kenttään on lisätty mahdollisuus haastaa ja tarkentaa aikataulupoikkeamien syykoodeja'
    ),
  ]),
  version('2.1.0', '2020-06-15T13:00:00+03:00', [
    newFeature('Yhteystietohaku', 'Kenttään on lisätty mahdollisuus hakea puhelinnumeroita'),
    newFeature(
      'Kokoonpanotiedot',
      'Lisätty kenttä näyttämään mahdollisia ylimääräisiä varoituksia'
    ),
    featureChange(
      'Työvuoro-ohje',
      'Kokoonpano- ja täsmällisyystietonapit eivät enää näy, jos tehtävään ei näitä tietoja liity'
    ),
  ]),
  version('2.0.3', '2020-06-03T12:30:00+03:00', [
    bugfix(
      'Täsmällisyystiedot',
      'Täsmällisyystiedot näkyvät taas sovelluksessa. Ominaisuus hajosi taustajärjestelmän päivityksen vuoksi.'
    ),
    bugfix('Työvuoro-ohje', 'Vaihtotöiden tunnukset näkyvät nyt uudessa käyttöliittymässä'),
    bugfix(
      'Työvuoro-ohje',
      'Tehty korjaus siihen, milloin tehtävän nimessä oleva numero tulkitaan junanumeroksi'
    ),
  ]),
  version('2.0.2', '2020-05-16T12:00:00+03:00', [
    bugfix('Työvuoro-ohje', 'Korjattu bugi jossa veturin muistilapputieto puuttui tehtävältä'),
  ]),
  version('2.0.1', '2020-05-16T12:00:00+03:00', [
    bugfix(
      'Työvuoro-ohje',
      'Pitkien tehtäväntunnisteiden (kuten vaihtotyöt) näkyvyyttä parannettu'
    ),
  ]),
  version('2.0.0', '2020-05-12T13:00:00+03:00', [
    featureChange(
      'Työvuoro-ohje',
      'Työvuoro-ohjeen käyttöliittymään on tehty laajoja muutoksia käyttäjäpalautteen perusteella. Luettelosta on pyritty tekemään kompaktimpi ja luettavampi.'
    ),
    featureChange(
      'Työvuoro-ohje',
      'Käyttörajoitteiden näyttötapa on muuttunut. Työtehtävän ohjeessa vasemmanpuoleisin nappi näkyy joko sinisenä, keltaisena tai punaisena, riippuen kriittisyydestä, mikäli tehtävän kalustoon liittyy käyttörajoitetietoja. Tarkempia tietoja pääsee tarkastelemaan nappia painamalla.'
    ),
    featureChange(
      'Yömoodi',
      'Yömoodi pysyy päällä, vaikka sivun lataa uudelleen tai käy välissä muilla sivustoilla'
    ),
    featureChange('Kokoonpanotiedot', 'Korostettu junan numeroa ja huomautukset-välilehteä'),
    bugfix(
      'Käyttörajoitukset',
      'Korjattu bugi, joka aiheutti väärien käyttörajoitteiden hakemisen välimuistista'
    ),
    bugfix(
      'Työvuoroluettelo',
      'Korjattu bugi, joka sai työvuoron keston heittämään tunnilla kellojen siirtojen yhteydessä'
    ),
    bugfix(
      'Työvuoroluettelo',
      'Korjattu virhe, joka aiheutti lepovuoron näkymisen kahden toisiinsa liittymättömän vuoron välissä'
    ),
    bugfix(
      'Työvuoroluettelo',
      'Korjattu virhe, joka salli käyttäjän ilmoittautua kaksiosaisen vuoron toiseen osioon erikseen joissain tilanteissa'
    ),
    bugfix('Yömoodi', 'Korjattu useiden näkymien värityksiä yömoodissa'),
    bugfix('Kokoonpanotiedot', 'Korjattu kokoonpanotietojen rivityksiä tablettinäkymässä'),
  ]),
  version('1.6.3', '2020-04-15T10:00:00+03:00', [
    featureChange('Taustapalvelut', 'Tehty kohennuksia taustajärjestelmän lokituksiin'),
  ]),
  version('1.6.1', '2020-03-24T10:00:00+03:00', [
    bugfix(
      'Työvuoroluettelo',
      'Korjattu virhe, jonka seurauksena kellojen siirtäminen saattoi aiheuttaa tunnin heiton työvuoron aikatauluissa'
    ),
  ]),
  version('1.5.1', '2020-03-04T10:00:00+03:00', [
    bugfix(
      'Ajotietotuloste',
      'Korjattu ajotietotulosteen latauksen vahvistuksen skaalausta tablettiresoluutioilla'
    ),
  ]),
  version('1.5.0', '2020-03-03T14:00:00+03:00', [
    bugfix(
      'Käyttörajoitetiedot',
      'Korjattu bugi, jonka seurauksena sovellus saattoi näyttää väärän kaluston käyttörajoitetiedot käyttörajoitelistauksessa'
    ),
  ]),
  version('1.4.0', '2020-02-12T14:00:00+03:00', [
    featureChange('Työvuoroluettelo', 'Siirretty ajotietotulosteen latausnappi sivun loppuosaan'),
    featureChange(
      'Työvuoro-ohje',
      'Siirretty tehtävän ajotietotulosteen latausnappi tehtävän loppuosaan'
    ),
    featureChange('Työvuoro-ohje', 'Siirretty kokoonpanotietojen nappi ylemmäs työvuoro-ohjeessa'),
    featureChange('Käyttörajoitetiedot', 'Tehty käyttörajoitetiedoista helpompia huomata'),
    featureChange('Työvuoroluettelo', 'Poistettu sijaintimerkintä lepovuoroista'),
    bugfix(
      'Käyttörajoitetiedot',
      'Korjattu käyttörajoitetietojen listauksen prioriteettijärjestys, kriittisin virhe näkyy nyt aina ylimpänä'
    ),
    bugfix('Hakusivut', 'Hakusivun navigaatiopalkki mahtuu nyt mobiilillakin yhdelle ruudulle'),
  ]),
  version('1.3.0', '2020-01-15T13:30:00+03:00', [
    bugfix(
      'Työvuoroluettelo',
      'Hidastettu työvuorojen haun aikakatkaisua, mikä aiheutti ongelmia ohjelmiston käyttämisessä hitailla verkkoyhteyksillä'
    ),
    bugfix(
      'Täsmällisyys',
      'Korjattu virhe, joka saattoi aiheuttaa ohjelman kaatumisen, jos sisääntuleva täsmällisyysdata oli puutteellista'
    ),
  ]),
  version('1.2.1', '2019-12-11T20:30:00+03:00', [
    featureChange('Käyttäjäryhmät', 'Käyttäjäryhmissä tehty pieniä muutoksia'),
    featureChange('Sovelluksen konfigurointiasetukset', 'Optimoitu sovelluksen asetuksia'),
  ]),
  version('1.2.0', '2019-12-11T10:30:00+03:00', [
    bugfix(
      'Etusivu',
      'Kaksiosaiset vuorot ja seuraavalle päivälle jatkuvat vuorot näkyvät nyt etusivulla päivän vaihtumisen jälkeenkin'
    ),
    bugfix(
      'Kokoonpanotiedot',
      'Korjattu taulukoiden asettelua mobiilinäkymässä, kun junalla on VAK-tietoja'
    ),
    bugfix(
      'Työvuoroluettelo',
      'Korjattu virhe, joka esti kaksiosaisten työvuorojen lepotaukojen näkymisen työvuoroluettelossa'
    ),
  ]),
  version('1.1.0', '2019-11-26T13:30:00+03:00', [
    bugfix(
      'Työvuoroluettelo',
      'Korjattu vuorojen pituuksien näyttämiseen liittyviä bugeja aloitusnäytössä ja työvuoro-ohjeissa'
    ),
    bugfix(
      'Käyttörajoitetiedot',
      'Välillä käyttörajoitetiedot tulivat myöhässä tai näkyivät virheellisesti, korjattu tähän liittyvä bugi'
    ),
    bugfix(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'Korjattu tapaus, jossa kokoonpanoja yritettiin hakea junanumerolla, jossa oli kirjaimia'
    ),
    bugfix(
      'Työvuoroluettelo',
      'Korjattu bugi, joka aiheutti väärän junan numeron näyttämisen työvuoroluettelon meneillään olevan vuoron alla'
    ),
    bugfix(
      'Käyttörajoitetiedot',
      'Kokoonpanonäkymän vaunukohtainen käyttörajoitteiden haku toimii nyt'
    ),
    bugfix(
      'Käyttörajoitetiedot',
      'Korjattu välimuistiongelma, joka aiheutti väärien käyttörajoitetietojen näyttämisen'
    ),
    featureChange('Työvuoro-ohje', 'Ohjeen ylälaidassa näytetään nyt vuoron kesto ilman lepoja'),
  ]),
  version('1.0.0', '2019-11-12T11:00:00+03:00', [
    featureChange(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'Parannettu jarrujen testauksen vahvistusviestien luettavuutta'
    ),
    bugfix(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'KAPU-junille ei enää näytetä VEMPU-linkkiä, jos kokoonpanoa ei löydy'
    ),
    newFeature(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'KAPU-junien vaununnumerot saa nyt näkyviin joko lyhyinä tai pitkinä'
    ),
    bugfix(
      'Työvuoroluettelo',
      'Kaksiosaisten työvuorojen kestot näkyvät nyt oikein työvuoroluettelossa'
    ),
    bugfix(
      'Työvuoro-ohje',
      'Veturin ottosijainnin pitäisi nyt näkyä työtehtävän lisätietojen yhteydessä'
    ),
    newFeature('Käyttäjäroolit', 'Sovellus tukee nyt paremmin erilaisia käyttäjärooleja'),
  ]),
  version('0.5.0', '2019-10-22T14:00:00+03:00', [
    featureChange(
      'Sovelluksen palaute',
      'Palautteen antaminen toimii nykyään lähettämällä suoraan sähköpostia kentta@avarko.com'
    ),
    featureChange(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'Parannettu kokoonpanonäkymän ulkoasua.'
    ),
    bugfix('Henkilökuntahaku', 'Muutettu yömoodin värejä näkyvämmäksi'),
    featureChange(
      'Työvuoro-ohje',
      'Matkustustehtävien otsakkeissa on nyt myös asiaankuuluvan junan numero'
    ),
    bugfix(
      'Käyttörajoitetietojen haun ja esittämisen parantaminen',
      'Korjattu käyttörajoitteiden ikonit huomioimaan tapaukset joissa työtehtävällä useampi veturi ja veturilla useampi vika'
    ),
    bugfix(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'Poistettu virheellinen aikaleima VET-junilta, kun TJT:tä ei oltu tehty'
    ),
  ]),
  version('0.4.0', '2019-10-09T09:30:00+03:00', [
    featureChange(
      'Työvuoro-ohjeiden haku',
      'Lisätty konduktööreille työvuoronäkymässä tehtävien otsakkeisiin junanumerot'
    ),
    featureChange(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'Kohennettu liikennöinti- ja jarrutarkastuksien ulkoasua ja toimivuutta'
    ),
    bugfix(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'Korjattu bugi, joka esti kokoonpanojen hakemisen ääkkösellisille sijainneille'
    ),
    bugfix(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'Tehty muutoksia kokoonpanolistojen luettavuuteen'
    ),
    bugfix(
      'Käyttörajoitteiden ja veturitehtävän tietojen näyttäminen',
      'Korjattu käyttörajoitteiden värityksiä'
    ),
  ]),
  version('0.3.0', '2019-08-22T14:00:00+03:00', [
    featureChange(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'Päivitetty kokoonpanonäkymän ulkoasua'
    ),
    bugfix(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'Korjattu useampia jarrupainoihin ja kokoonpanoihin liittyviä bugeja'
    ),
  ]),
  version('0.2.6', '2019-08-22T14:00:00+03:00', [
    featureChange(
      'Junien täsmällisyys',
      'Ajamattomien junien täsmällisyyssivun ulkoasua päivitetty'
    ),
    featureChange('Junien täsmällisyys', 'Syykoodien värit indikoivat nyt vastuullista osapuolta'),
    bugfix('Junien täsmällisyys', 'Vaihtotöiden täsmällisyyksien pitäisi nyt näkyä'),
    featureChange(
      'Junien täsmällisyys',
      'Muutettu logiikkaa, jolla tuotetaan arvo työvuoronäkymän työvuoroihin'
    ),
  ]),
  version('0.2.5', '2019-08-12T13:00:00+03:00', [
    featureChange('Junien täsmällisyys', 'Päivitetty täsmällisyyssivun ulkoasua'),
    featureChange(
      'Junien täsmällisyys',
      'Täsmällisyystietoja päivitetään automaattisesti taustalla (minuutin välein)'
    ),
  ]),
  version('0.2.4', '2019-07-31T15:30:00+03:00', [
    newFeature(
      'Junien täsmällisyys',
      'Työvuoron junille näytetään täsmällisyystiedot toteutuneiden ohitusaikojen ja avoimesta rajapinnasta saatavien syykoodien avulla.'
    ),
    bugfix('Henkilökuntahaku', 'Korjaus päivämäärien käsittelyyn.'),
    featureChange(
      'Työvuoro-ohjeiden haku',
      'Työvuorojen ohjeet haetaan uudesta rajapinnasta, jonka tulisi olla hieman tehokkaampi.'
    ),
  ]),
  version('0.2.3', '2019-07-26T15:00:00+03:00', [
    featureChange(
      'Haut taustajärjestelmään',
      'Parannuksia käyttäjän session vanhenemisen käsittelyyn.'
    ),
    featureChange('Asematiedot', 'Asematietodatan päivitys tuoreimman tiedon mukaisesti.'),
    featureChange('Kokoonpanohaku', 'Parannus hakuhistorian toimintaan.'),
  ]),
  version('0.2.2', '2019-06-20T16:00:00+03:00', [
    featureChange('Kokoonpanohaku', 'Parannuksia kokoonpanojen esittämiseen ja hakemiseen.'),
  ]),
  version('0.2.1', '2019-05-28T16:00:00+03:00', [
    featureChange(
      'Hakusivut',
      'Parannettu virheviestien toimintaa, poistettu hämäävät vihjetekstit syöttökentistä + muita pieniä parannuksia käytettävyyteen ja ulkoasuun.'
    ),
    newFeature(
      'Hakutuloshistoria',
      'Vanhoja hakuja pystyy poistamaan ja niihin lisätty myös automaattinen poisto 36 tunnin jälkeen.'
    ),
  ]),
  version('0.2.0', '2019-05-08T08:00:00+03:00', [
    featureChange(
      'Taustajärjestelmän ympäristö',
      'Siirrytty Lambda-pohajaisesta ympäristöstä kontitettuun ympäristöön. Muutoksen toivotaan parantavan sovelluksen vasteaikoja.'
    ),
    newFeature(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'Lisätty kokoonpanojen hakutoiminnallisuus hakusivulle ja työvuoro-ohjeille.'
    ),
    newFeature(
      'Vara-aikataulujen haku',
      'Lisätty vara-aikataulujen hakutoiminnallisuus hakusivulle ja työvuoro-ohjeille.'
    ),
  ]),
  version('0.1.7', '2019-01-08T13:32:00+03:00', [
    newFeature(
      'Käyttörajoitteiden ja veturitehtävän tietojen näyttäminen',
      'Lisätty työvuoro-ohjeille veturien käyttörajoitteiden haku. Työvuoroilla näytetään myös veturin sijaintitiedot ja huomiot.'
    ),
  ]),
  version('0.1.6', '2018-10-26T13:32:00+03:00', [
    newFeature(
      'Työvuoro-ohjehaku',
      'Lisätty työvuoro-ohjeiden hakuominaisuus. Haku toimii päivämäärällä, lähtöasemalla ja junanumerolla, tai päivämäärällä ja työvuorotunnuksella.'
    ),
  ]),
  version('0.1.5', '2018-10-26T13:32:00+03:00', [
    newFeature(
      'Henkilökuntahaku',
      'Lisätty henkilökunnan hakuominaisuus. Haku toimii päivämäärällä ja junanumerolla.'
    ),
  ]),
  version('0.1.4', '2018-05-11T11:49:48+03:00', [
    featureChange(
      'Työvuoro-ohje',
      'Puuttuva työvuoron päättymisaika päätellään työvuoron viimeisen tehtävän lopetusajankohdasta ja työvuoron lopetusajasta.'
    ),
    bugfix(
      'Työvuoroluettelo',
      'Työvuoroluettelo ei näytä enää latausindikaattoria kun lataus on päättynyt.'
    ),
  ]),
  version('0.1.3', '2018-04-27T12:01:01+03:00', [
    featureChange(
      'Työvuoroon ilmoittautuminen',
      'eSäLListä poistetut työvuoroon ilmoittautumiset poistuvat nyt myös Kenttä -sovelluksesta työvuoroluettelon päivityksen yhteydessä.'
    ),
  ]),
  version('0.1.2', '2018-04-20T12:51:37+03:00', [
    featureChange(
      'Työvuoropalaute',
      'Työvuorosta voi nyt antaa palautetta heti työvuoroon ilmoittautumisen jälkeen.'
    ),
    featureChange(
      'Työvuoro-ohje',
      'Työvuoro-ohjeet latautuvat nyt vasta työvuoro-ohje näkymää avattaessa.'
    ),
  ]),
  version('0.1.1', '2018-04-13T16:26:46+03:00', [
    featureChange('Työvuoropalaute', 'Työvuoropalaute on nyt aktivoitu pilottiympäristössä.'),
    newFeature(
      'Ilmoitus versiopäivityksestä',
      'Sovelluksen päivittyessä uuteen versioon käyttäjälle näytetään ilmoitus.'
    ),
  ]),
  version('0.1.0', '2018-04-06T14:31:48+03:00', [
    newFeature(
      'Tietoa sovelluksesta',
      '"Lisää" -sivulle lisätty linkki "Tietoa sovelluksesta" -sivulle.'
    ),
    newFeature('Versiohistoria', 'Lisätty versiohistoria "Tietoa sovelluksesta" -sivulle.'),
    newFeature(
      'Tehtävien merkkaaminen tehdyiksi',
      'Tehdyiksi merkattuja tehtäviä voi nyt merkata tekemättömiksi.'
    ),
    bugfix('Tehtävän asematieto', 'Tehtävien asematieto ei leikkaudu enää pienillä ruuduilla.'),
  ]),
  version('0.0.9', '2018-03-23T13:27:00+02:00', [
    newFeature(
      'Ympäristö',
      'Ohjelmiston ympäristö (tuotanto tai kehitys) näkyy nyt versionumerossa teksteinä dev tai prod.'
    ),
    featureChange(
      'Tehtävien kuittaaminen tehdyiksi',
      'Tehtäviä voidaan nyt kuitata valmiiksi vain vuoroista joihin ollaan ilmoittauduttu.'
    ),
    featureChange(
      'Vastaus työvuoropalautteeseen',
      'Työvuoropalauteen vastaus on nyt näkyvissä myös palautesivulla.'
    ),
    bugfix(
      'Palautteen tähtimerkintä',
      'Palautteen tähtimerkinnän asettelu korjattu Internet Explorerissa.'
    ),
    bugfix(
      'Työvuoroluettelo',
      'Työvuoroluettelon "seuraava kuukausi" komponentin tekstin väri korjattu.'
    ),
  ]),
  version('0.0.8', '2018-03-13T12:17:53+02:00', [
    featureChange(
      'Offline tila',
      'Offline tilan tarkistusta yksinkertaistettu, poistettiin "heikko yhteys" välitila.'
    ),
    bugfix(
      'Palaute työvuorolistasta',
      'Työvuorolistapalautteen avaava widget sulkeutuu kun siirrytään palautesivulle.'
    ),
    bugfix(
      'Työvuoroluottelo',
      'Työvuoroluettelossa ei näytetä enää lepoja joiden pituus on 0 minuuttia.'
    ),
  ]),
  version('0.0.7', '2018-03-09T15:19:25+02:00', [
    featureChange(
      'Työvuoroon ilmoittautuminen',
      'Menneissä työvuoroissa ei näytetä enää "Ilmoittaudu työvuoroon" -painiketta.'
    ),
  ]),
  version('0.0.6', '2018-03-05T10:27:14+02:00', [
    featureChange(
      'Tehtävän yhteystiedot',
      'Tehtävän yhteystietojen väritystä parannettu käytettäessä yöteemaa.'
    ),
    featureChange(
      'Päivämäärien muotoilu',
      'Nykyhetkestä kaukana olevien päivämäärien esitystapaa muutettu.'
    ),
  ]),
  version('0.0.5', '2018-02-26T14:43:17+02:00', [
    bugfix('Työvuoron huomio', 'Työvuoron huomio lisätty työvuoro-ohjeen alkuun.'),
    featureChange(
      'Tehtävän yhteystiedot',
      'Yhteystietojen asettelua muokattu mahtumaan pienempään tilaan.'
    ),
    featureChange('Ratatiedot', 'Tehtävän lähtö- ja tuloraide näkyy nyt tehtävärivillä.'),
  ]),
]

export default changelog_prod
